import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from './dropOffSmall.png'; // Update the path to the new location
import useTypingEffect from './useTypingEffect';

function LandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMissionOpen, setIsMissionOpen] = useState(true); // Mission section opens immediately
  const [isValuesOpen, setIsValuesOpen] = useState(false);
  const [isEventsOpen, setIsEventsOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('');
  const [priceVisibility, setPriceVisibility] = useState({});

  const missionText = "Drop Off is dedicated to enhancing the skateboarding experience in Manhattan by coordinating events that support local businesses and build community.";
  const valuesText = "We value inclusivity, creativity, and community. Our events and initiatives aim to foster a supportive environment for all skateboarders.";
  const eventsText = "Check out our upcoming events and join us to celebrate the skateboarding culture. We host regular meet-ups, competitions, and community-building activities.";

  const displayedMissionText = useTypingEffect(isMissionOpen ? missionText : '', 50);
  const displayedValuesText = useTypingEffect(isValuesOpen ? valuesText : '', 50);
  const displayedEventsText = useTypingEffect(isEventsOpen ? eventsText : '', 50);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleMission = () => {
    setIsMissionOpen(!isMissionOpen);
  };

  const toggleValues = () => {
    setIsValuesOpen(!isValuesOpen);
  };

  const toggleEvents = () => {
    setIsEventsOpen(!isEventsOpen);
  };

  const toggleCategory = (category) => {
    if (activeCategory === category) {
      setActiveCategory('');
      setPriceVisibility({});
    } else {
      setActiveCategory(category);
      setPriceVisibility({});
    }
  };

  useEffect(() => {
    if (activeCategory) {
      const timeoutIds = [];
      const prices = Object.keys(getCategoryContent(activeCategory));
      prices.forEach((price, index) => {
        const timeoutId = setTimeout(() => {
          setPriceVisibility((prev) => ({ ...prev, [price]: true }));
        }, index * 100);
        timeoutIds.push(timeoutId);
      });

      return () => {
        timeoutIds.forEach(clearTimeout);
      };
    }
  }, [activeCategory]);

  const getCategoryContent = (category) => {
    switch (category) {
      case 'boards':
        return { title: 'Boards', description: 'Explore our exclusive collection of boards.', price: '$65', image: '/images/decks.png' };
      case 'scarfs':
        return { title: 'Scarfs', description: 'Stay warm with our stylish scarfs.', price: '$40', image: '/images/scarfs.png' };
      case 'white_tshirts':
        return { title: 'White T-Shirts', description: 'Classic white t-shirts for any occasion.', price: '$35', image: '/images/tshirts.png' };
      case 'color_tshirts':
        return { title: 'Color T-Shirts', description: 'Colorful t-shirts to brighten your day.', price: '$45', image: '/images/tshirts-colored.png' };
      case 'patches':
        return { title: 'Patches', description: 'Customize your gear with our patches.', price: '$5', image: '/images/patch.png' };
      case 'shorts':
        return { title: 'Shorts', description: 'Comfortable shorts for skateboarding.', price: '$40', image: '/images/redShorts.png' };
      default:
        return {};
    }
  };

  return (

    
    <div className="relative h-screen overflow-hidden">
      <video autoPlay playsInline loop muted className="absolute top-0 left-0 w-full h-full object-cover">
        <source src="https://storage.googleapis.com/bulabucket/dropOff/optimized_video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <nav className="flex items-center justify-between p-4 bg-black bg-opacity-75 text-white relative z-10">
        <div className="flex items-center space-x-4">
          <button className="h-6 w-6 cursor-pointer" onClick={toggleMenu}>
            <MenuIcon />
          </button>
          <span className="hidden md:inline">EVENTS</span>
          <Link to="/merch" className="hidden md:inline">MERCH</Link>
          <Link to="/mission" className="hidden md:inline">MISSION</Link>
        </div>
        <span className="block md:hidden absolute left-1/2 transform -translate-x-1/2">DROPOFF</span>
        <span className="hidden md:block absolute left-1/2 transform -translate-x-1/2">DROPOFF</span>
        <div className="flex items-center space-x-4">
          <span className="hidden md:inline">SEARCH</span>
          <input type="text" placeholder="Search" className="hidden md:inline bg-transparent border-b border-white focus:outline-none" />
          <SearchIcon className="hidden md:inline h-6 w-6" />
          <span>CART</span>
        </div>
      </nav>
      {isMenuOpen && (
        <div className="absolute top-0 left-0 w-full md:w-1/3 h-full bg-white z-20 p-4 overflow-y-auto">
          <div className="flex justify-between items-center">
            <span className="text-black text-2xl font-bold">DROPOFF</span>
            <button className="text-black text-lg" onClick={toggleMenu}>CLOSE</button>
          </div>
          <div className="mt-4">
            <span className="block text-black text-lg">SEARCH</span>
            <input type="text" className="w-full bg-transparent border-b border-black focus:outline-none mt-2" />
            <div className="flex items-center mt-2">
              <SearchIcon className="h-6 w-6 text-black" />
            </div>
            <div className="mt-8">
              <span className="block text-black text-lg">NEW</span>
              <div className="mt-4">
                <div
                  className="block text-black relative cursor-pointer"
                  onClick={() => toggleCategory('boards')}
                >
                  A. BOARDS
                  {activeCategory === 'boards' && (
                    <div className="mt-2">
                      <img
                        src={getCategoryContent(activeCategory).image}
                        alt="boards"
                        className="max-w-xs max-h-48 mb-4"
                      />
                      <h2 className="text-2xl font-bold">{getCategoryContent(activeCategory).title}</h2>
                      <p>{getCategoryContent(activeCategory).description}</p>
                      {priceVisibility['price'] && <p className="font-mono text-2xl">{getCategoryContent(activeCategory).price}</p>}
                      <Link to="https://gju6wciqmt9.typeform.com/to/z8Dqy2G4">
                        <button className="mt-2 bg-black text-white px-4 py-2 rounded font-mono">Order</button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <div
                  className="block text-black relative cursor-pointer"
                  onClick={() => toggleCategory('scarfs')}
                >
                  B. SCARFS
                  {activeCategory === 'scarfs' && (
                    <div className="mt-2">
                      <img
                        src={getCategoryContent(activeCategory).image}
                        alt="scarfs"
                        className="max-w-xs max-h-48 mb-4"
                      />
                      <h2 className="text-2xl font-bold">{getCategoryContent(activeCategory).title}</h2>
                      <p>{getCategoryContent(activeCategory).description}</p>
                      {priceVisibility['price'] && <p className="font-mono text-2xl">{getCategoryContent(activeCategory).price}</p>}
                      <Link to="https://gju6wciqmt9.typeform.com/to/z8Dqy2G4">
                        <button className="mt-2 bg-black text-white px-4 py-2 rounded font-mono">Order</button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <div
                  className="block text-black relative cursor-pointer"
                  onClick={() => toggleCategory('tshirts')}
                >
                  C. T-SHIRTS
                </div>
                <div
                  className="block text-black ml-4 relative cursor-pointer"
                  onClick={() => toggleCategory('white_tshirts')}
                >
                  - WHITE T-SHIRTS
                  {activeCategory === 'white_tshirts' && (
                    <div className="mt-2">
                      <img
                        src={getCategoryContent(activeCategory).image}
                        alt="white_tshirts"
                        className="max-w-xs max-h-48 mb-4"
                      />
                      <h2 className="text-2xl font-bold">{getCategoryContent(activeCategory).title}</h2>
                      <p>{getCategoryContent(activeCategory).description}</p>
                      {priceVisibility['price'] && <p className="font-mono text-2xl">{getCategoryContent(activeCategory).price}</p>}
                      <Link to="https://gju6wciqmt9.typeform.com/to/z8Dqy2G4">
                        <button className="mt-2 bg-black text-white px-4 py-2 rounded font-mono">Order</button>
                      </Link>
                    </div>
                  )}
                </div>
                <div
                  className="block text-black ml-4 relative cursor-pointer"
                  onClick={() => toggleCategory('color_tshirts')}
                >
                  - COLOR T-SHIRTS
                  {activeCategory === 'color_tshirts' && (
                    <div className="mt-2">
                      <img
                        src={getCategoryContent(activeCategory).image}
                        alt="color_tshirts"
                        className="max-w-xs max-h-48 mb-4"
                      />
                      <h2 className="text-2xl font-bold">{getCategoryContent(activeCategory).title}</h2>
                      <p>{getCategoryContent(activeCategory).description}</p>
                      {priceVisibility['price'] && <p className="font-mono text-2xl">{getCategoryContent(activeCategory).price}</p>}
                      <Link to="https://gju6wciqmt9.typeform.com/to/z8Dqy2G4">
                        <button className="mt-2 bg-black text-white px-4 py-2 rounded font-mono">Order</button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <div
                  className="block text-black relative cursor-pointer"
                  onClick={() => toggleCategory('patches')}
                >
                  E. PATCHES
                  {activeCategory === 'patches' && (
                    <div className="mt-2">
                      <img
                        src={getCategoryContent(activeCategory).image}
                        alt="patches"
                        className="max-w-xs max-h-48 mb-4"
                      />
                      <h2 className="text-2xl font-bold">{getCategoryContent(activeCategory).title}</h2>
                      <p>{getCategoryContent(activeCategory).description}</p>
                      {priceVisibility['price'] && <p className="font-mono text-2xl">{getCategoryContent(activeCategory).price}</p>}
                      <Link to="https://gju6wciqmt9.typeform.com/to/z8Dqy2G4">
                        <button className="mt-2 bg-black text-white px-4 py-2 rounded font-mono">Order</button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <div
                  className="block text-black relative cursor-pointer"
                  onClick={() => toggleCategory('shorts')}
                >
                  G. RED SHORTS
                  {activeCategory === 'shorts' && (
                    <div className="mt-2">
                      <img
                        src={getCategoryContent(activeCategory).image}
                        alt="shorts"
                        className="max-w-xs max-h-48 mb-4"
                      />
                      <h2 className="text-2xl font-bold">{getCategoryContent(activeCategory).title}</h2>
                      <p>{getCategoryContent(activeCategory).description}</p>
                      {priceVisibility['price'] && <p className="font-mono text-2xl">{getCategoryContent(activeCategory).price}</p>}
                      <Link to="https://gju6wciqmt9.typeform.com/to/z8Dqy2G4">
                        <button className="mt-2 bg-black text-white px-4 py-2 rounded font-mono">Order</button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="absolute bottom-0 left-0 right-0 p-8 text-white z-10">
        <div onClick={toggleMission} className="cursor-pointer mb-4">
          <h1 className="text-3xl font-bold">Interactive Skate Community</h1>
          {isMissionOpen && (
            <div className="text-lg mt-2 font-mono bg-black bg-opacity-75 p-4 rounded" style={{ color: '#d3d3d3', fontFamily: 'Courier, monospace' }}>
              <p>{displayedMissionText}</p>
            </div>
          )}
        </div>
        <div onClick={toggleValues} className="cursor-pointer mb-4">
          <h1 className="text-3xl font-bold">Our Values</h1>
          {isValuesOpen && (
            <div className="text-lg mt-2 font-mono bg-black bg-opacity-75 p-4 rounded" style={{ color: '#d3d3d3', fontFamily: 'Courier, monospace' }}>
              <p>{displayedValuesText}</p>
            </div>
          )}
        </div>
        <div onClick={toggleEvents} className="cursor-pointer mb-4">
          <h1 className="text-3xl font-bold">Events</h1>
          {isEventsOpen && (
            <div className="text-lg mt-2 font-mono bg-black bg-opacity-75 p-4 rounded" style={{ color: '#d3d3d3', fontFamily: 'Courier, monospace' }}>
              <p>{displayedEventsText}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}

function SearchIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="m21 21-4.3-4.3" />
    </svg>
  );
}

export default LandingPage;
