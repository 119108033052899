import React from 'react';

function MerchPage() {
  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-4">Merchandise</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="p-4 border border-white">
          <h2 className="text-xl font-bold">Shirts</h2>
          <p className="mt-2">Explore our exclusive collection of shirts.</p>
        </div>
        <div className="p-4 border border-white">
          <h2 className="text-xl font-bold">Boards</h2>
          <p className="mt-2">Discover a variety of skateboards for all levels.</p>
        </div>
        <div className="p-4 border border-white">
          <h2 className="text-xl font-bold">Sweaters</h2>
          <p className="mt-2">Check out our cozy and stylish sweaters.</p>
        </div>
      </div>
    </div>
  );
}

export default MerchPage;
